const middleware = {}

middleware['authorizationGuard'] = require('../middleware/authorizationGuard.js')
middleware['authorizationGuard'] = middleware['authorizationGuard'].default || middleware['authorizationGuard']

middleware['cabinetGuard'] = require('../middleware/cabinetGuard.js')
middleware['cabinetGuard'] = middleware['cabinetGuard'].default || middleware['cabinetGuard']

middleware['referral-redirect'] = require('../middleware/referral-redirect.js')
middleware['referral-redirect'] = middleware['referral-redirect'].default || middleware['referral-redirect']

export default middleware
