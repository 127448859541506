import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _133467ca = () => interopDefault(import('../pages/api/verify-email/index.vue' /* webpackChunkName: "pages/api/verify-email/index" */))
const _630b2ef4 = () => interopDefault(import('../pages/authorization/recovery-password/index.vue' /* webpackChunkName: "pages/authorization/recovery-password/index" */))
const _68ae6388 = () => interopDefault(import('../pages/authorization/registration/index.vue' /* webpackChunkName: "pages/authorization/registration/index" */))
const _584c7cdc = () => interopDefault(import('../pages/authorization/reset-password/index.vue' /* webpackChunkName: "pages/authorization/reset-password/index" */))
const _722e9168 = () => interopDefault(import('../pages/authorization/sign-in/index.vue' /* webpackChunkName: "pages/authorization/sign-in/index" */))
const _249ef7a6 = () => interopDefault(import('../pages/cabinet/blog/index.vue' /* webpackChunkName: "pages/cabinet/blog/index" */))
const _b2104234 = () => interopDefault(import('../pages/cabinet/dashboard/index.vue' /* webpackChunkName: "pages/cabinet/dashboard/index" */))
const _801d2bec = () => interopDefault(import('../pages/cabinet/documents/index.vue' /* webpackChunkName: "pages/cabinet/documents/index" */))
const _37790c67 = () => interopDefault(import('../pages/cabinet/exchange/index.vue' /* webpackChunkName: "pages/cabinet/exchange/index" */))
const _6cc00bb0 = () => interopDefault(import('../pages/cabinet/faq/index.vue' /* webpackChunkName: "pages/cabinet/faq/index" */))
const _c65508a8 = () => interopDefault(import('../pages/cabinet/insurance/index.vue' /* webpackChunkName: "pages/cabinet/insurance/index" */))
const _6db4c997 = () => interopDefault(import('../pages/cabinet/news/index.vue' /* webpackChunkName: "pages/cabinet/news/index" */))
const _6b46e961 = () => interopDefault(import('../pages/cabinet/referral/index.vue' /* webpackChunkName: "pages/cabinet/referral/index" */))
const _47221afa = () => interopDefault(import('../pages/cabinet/refill/index.vue' /* webpackChunkName: "pages/cabinet/refill/index" */))
const _00cf40e2 = () => interopDefault(import('../pages/cabinet/services/index.vue' /* webpackChunkName: "pages/cabinet/services/index" */))
const _51548821 = () => interopDefault(import('../pages/cabinet/support/index.vue' /* webpackChunkName: "pages/cabinet/support/index" */))
const _60d8b606 = () => interopDefault(import('../pages/cabinet/wallet/index.vue' /* webpackChunkName: "pages/cabinet/wallet/index" */))
const _cc75744c = () => interopDefault(import('../pages/cabinet/security/account-verify/index.vue' /* webpackChunkName: "pages/cabinet/security/account-verify/index" */))
const _538cf179 = () => interopDefault(import('../pages/cabinet/security/authentication/index.vue' /* webpackChunkName: "pages/cabinet/security/authentication/index" */))
const _fe26f2de = () => interopDefault(import('../pages/cabinet/security/confirm-profile/index.vue' /* webpackChunkName: "pages/cabinet/security/confirm-profile/index" */))
const _9288ba80 = () => interopDefault(import('../pages/cabinet/security/devices-activity/index.vue' /* webpackChunkName: "pages/cabinet/security/devices-activity/index" */))
const _0ca31e5c = () => interopDefault(import('../pages/cabinet/security/password/index.vue' /* webpackChunkName: "pages/cabinet/security/password/index" */))
const _a994ed0a = () => interopDefault(import('../pages/cabinet/services/earn/index.vue' /* webpackChunkName: "pages/cabinet/services/earn/index" */))
const _eb16a426 = () => interopDefault(import('../pages/cabinet/services/insurance/index.vue' /* webpackChunkName: "pages/cabinet/services/insurance/index" */))
const _5929a794 = () => interopDefault(import('../pages/cabinet/services/mine/index.vue' /* webpackChunkName: "pages/cabinet/services/mine/index" */))
const _6ff6d2de = () => interopDefault(import('../pages/cabinet/settings/analytics/index.vue' /* webpackChunkName: "pages/cabinet/settings/analytics/index" */))
const _036659c4 = () => interopDefault(import('../pages/cabinet/settings/information/index.vue' /* webpackChunkName: "pages/cabinet/settings/information/index" */))
const _0befd340 = () => interopDefault(import('../pages/cabinet/settings/notifications/index.vue' /* webpackChunkName: "pages/cabinet/settings/notifications/index" */))
const _7d887c81 = () => interopDefault(import('../pages/cabinet/settings/profile/index.vue' /* webpackChunkName: "pages/cabinet/settings/profile/index" */))
const _2df73937 = () => interopDefault(import('../pages/cabinet/settings/wallet/index.vue' /* webpackChunkName: "pages/cabinet/settings/wallet/index" */))
const _c4693a84 = () => interopDefault(import('../pages/cabinet/documents/id/_id.vue' /* webpackChunkName: "pages/cabinet/documents/id/_id" */))
const _460e7456 = () => interopDefault(import('../pages/cabinet/services/earn/_name/index.vue' /* webpackChunkName: "pages/cabinet/services/earn/_name/index" */))
const _68e8fb48 = () => interopDefault(import('../pages/cabinet/services/insurance/_name/index.vue' /* webpackChunkName: "pages/cabinet/services/insurance/_name/index" */))
const _082fded1 = () => interopDefault(import('../pages/cabinet/services/mine/_name/index.vue' /* webpackChunkName: "pages/cabinet/services/mine/_name/index" */))
const _4689c9f4 = () => interopDefault(import('../pages/cabinet/services/name/_name.vue' /* webpackChunkName: "pages/cabinet/services/name/_name" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/api/verify-email",
    component: _133467ca,
    name: "api-verify-email"
  }, {
    path: "/authorization/recovery-password",
    component: _630b2ef4,
    name: "authorization-recovery-password"
  }, {
    path: "/authorization/registration",
    component: _68ae6388,
    name: "authorization-registration"
  }, {
    path: "/authorization/reset-password",
    component: _584c7cdc,
    name: "authorization-reset-password"
  }, {
    path: "/authorization/sign-in",
    component: _722e9168,
    name: "authorization-sign-in"
  }, {
    path: "/cabinet/blog",
    component: _249ef7a6,
    name: "cabinet-blog"
  }, {
    path: "/cabinet/dashboard",
    component: _b2104234,
    name: "cabinet-dashboard"
  }, {
    path: "/cabinet/documents",
    component: _801d2bec,
    name: "cabinet-documents"
  }, {
    path: "/cabinet/exchange",
    component: _37790c67,
    name: "cabinet-exchange"
  }, {
    path: "/cabinet/faq",
    component: _6cc00bb0,
    name: "cabinet-faq"
  }, {
    path: "/cabinet/insurance",
    component: _c65508a8,
    name: "cabinet-insurance"
  }, {
    path: "/cabinet/news",
    component: _6db4c997,
    name: "cabinet-news"
  }, {
    path: "/cabinet/referral",
    component: _6b46e961,
    name: "cabinet-referral"
  }, {
    path: "/cabinet/refill",
    component: _47221afa,
    name: "cabinet-refill"
  }, {
    path: "/cabinet/services",
    component: _00cf40e2,
    name: "cabinet-services"
  }, {
    path: "/cabinet/support",
    component: _51548821,
    name: "cabinet-support"
  }, {
    path: "/cabinet/wallet",
    component: _60d8b606,
    name: "cabinet-wallet"
  }, {
    path: "/cabinet/security/account-verify",
    component: _cc75744c,
    name: "cabinet-security-account-verify"
  }, {
    path: "/cabinet/security/authentication",
    component: _538cf179,
    name: "cabinet-security-authentication"
  }, {
    path: "/cabinet/security/confirm-profile",
    component: _fe26f2de,
    name: "cabinet-security-confirm-profile"
  }, {
    path: "/cabinet/security/devices-activity",
    component: _9288ba80,
    name: "cabinet-security-devices-activity"
  }, {
    path: "/cabinet/security/password",
    component: _0ca31e5c,
    name: "cabinet-security-password"
  }, {
    path: "/cabinet/services/earn",
    component: _a994ed0a,
    name: "cabinet-services-earn"
  }, {
    path: "/cabinet/services/insurance",
    component: _eb16a426,
    name: "cabinet-services-insurance"
  }, {
    path: "/cabinet/services/mine",
    component: _5929a794,
    name: "cabinet-services-mine"
  }, {
    path: "/cabinet/settings/analytics",
    component: _6ff6d2de,
    name: "cabinet-settings-analytics"
  }, {
    path: "/cabinet/settings/information",
    component: _036659c4,
    name: "cabinet-settings-information"
  }, {
    path: "/cabinet/settings/notifications",
    component: _0befd340,
    name: "cabinet-settings-notifications"
  }, {
    path: "/cabinet/settings/profile",
    component: _7d887c81,
    name: "cabinet-settings-profile"
  }, {
    path: "/cabinet/settings/wallet",
    component: _2df73937,
    name: "cabinet-settings-wallet"
  }, {
    path: "/cabinet/documents/id/:id?",
    component: _c4693a84,
    name: "cabinet-documents-id-id"
  }, {
    path: "/cabinet/services/earn/:name",
    component: _460e7456,
    name: "cabinet-services-earn-name"
  }, {
    path: "/cabinet/services/insurance/:name",
    component: _68e8fb48,
    name: "cabinet-services-insurance-name"
  }, {
    path: "/cabinet/services/mine/:name",
    component: _082fded1,
    name: "cabinet-services-mine-name"
  }, {
    path: "/cabinet/services/name/:name?",
    component: _4689c9f4,
    name: "cabinet-services-name-name"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
