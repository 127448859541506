export const DocumentsContainer = () => import('../../components/Documents/DocumentsContainer.vue' /* webpackChunkName: "components/documents-container" */).then(c => wrapFunctional(c.default || c))
export const FAQQuestionContainer = () => import('../../components/FAQ/QuestionContainer.vue' /* webpackChunkName: "components/f-a-q-question-container" */).then(c => wrapFunctional(c.default || c))
export const ReferralInvite = () => import('../../components/Referral/ReferralInvite.vue' /* webpackChunkName: "components/referral-invite" */).then(c => wrapFunctional(c.default || c))
export const SecurityNavigation = () => import('../../components/Security/SecurityNavigation.vue' /* webpackChunkName: "components/security-navigation" */).then(c => wrapFunctional(c.default || c))
export const SettingsNavigation = () => import('../../components/Settings/SettingsNavigation.vue' /* webpackChunkName: "components/settings-navigation" */).then(c => wrapFunctional(c.default || c))
export const UIVButton = () => import('../../components/UI/VButton.vue' /* webpackChunkName: "components/u-i-v-button" */).then(c => wrapFunctional(c.default || c))
export const UIVCheckbox = () => import('../../components/UI/VCheckbox.vue' /* webpackChunkName: "components/u-i-v-checkbox" */).then(c => wrapFunctional(c.default || c))
export const UIVDatepicker = () => import('../../components/UI/VDatepicker.vue' /* webpackChunkName: "components/u-i-v-datepicker" */).then(c => wrapFunctional(c.default || c))
export const UIVError = () => import('../../components/UI/VError.vue' /* webpackChunkName: "components/u-i-v-error" */).then(c => wrapFunctional(c.default || c))
export const UIVIcon = () => import('../../components/UI/VIcon.vue' /* webpackChunkName: "components/u-i-v-icon" */).then(c => wrapFunctional(c.default || c))
export const UIVInput = () => import('../../components/UI/VInput.vue' /* webpackChunkName: "components/u-i-v-input" */).then(c => wrapFunctional(c.default || c))
export const UIVLabel = () => import('../../components/UI/VLabel.vue' /* webpackChunkName: "components/u-i-v-label" */).then(c => wrapFunctional(c.default || c))
export const UIVPreloader = () => import('../../components/UI/VPreloader.vue' /* webpackChunkName: "components/u-i-v-preloader" */).then(c => wrapFunctional(c.default || c))
export const UIVSelect = () => import('../../components/UI/VSelect.vue' /* webpackChunkName: "components/u-i-v-select" */).then(c => wrapFunctional(c.default || c))
export const UIVTitle = () => import('../../components/UI/VTitle.vue' /* webpackChunkName: "components/u-i-v-title" */).then(c => wrapFunctional(c.default || c))
export const UIVTooltip = () => import('../../components/UI/VTooltip.vue' /* webpackChunkName: "components/u-i-v-tooltip" */).then(c => wrapFunctional(c.default || c))
export const DocumentsRowDocumentRow = () => import('../../components/Documents/Row/DocumentRow.vue' /* webpackChunkName: "components/documents-row-document-row" */).then(c => wrapFunctional(c.default || c))
export const FAQRowQuestionRow = () => import('../../components/FAQ/Row/QuestionRow.vue' /* webpackChunkName: "components/f-a-q-row-question-row" */).then(c => wrapFunctional(c.default || c))
export const HomeAccountVerification = () => import('../../components/Home/Account/AccountVerification.vue' /* webpackChunkName: "components/home-account-verification" */).then(c => wrapFunctional(c.default || c))
export const HomeDemoMode = () => import('../../components/Home/DemoMode/DemoMode.vue' /* webpackChunkName: "components/home-demo-mode" */).then(c => wrapFunctional(c.default || c))
export const HomeStatiscticsStatistics = () => import('../../components/Home/Statisctics/Statistics.vue' /* webpackChunkName: "components/home-statisctics-statistics" */).then(c => wrapFunctional(c.default || c))
export const ReferralMyReferralLink = () => import('../../components/Referral/MyReferralLink/MyReferralLink.vue' /* webpackChunkName: "components/referral-my-referral-link" */).then(c => wrapFunctional(c.default || c))
export const ReferralMyReferralNetwork = () => import('../../components/Referral/MyReferralNetwork/MyReferralNetwork.vue' /* webpackChunkName: "components/referral-my-referral-network" */).then(c => wrapFunctional(c.default || c))
export const ReferralMyReferralNetworkLink = () => import('../../components/Referral/MyReferralNetworkLink/MyReferralNetworkLink.vue' /* webpackChunkName: "components/referral-my-referral-network-link" */).then(c => wrapFunctional(c.default || c))
export const ReferralNetwork = () => import('../../components/Referral/ReferralNetwork/ReferralNetwork.vue' /* webpackChunkName: "components/referral-network" */).then(c => wrapFunctional(c.default || c))
export const ReferralWalletReview = () => import('../../components/Referral/ReferralWalletReview/ReferralWalletReview.vue' /* webpackChunkName: "components/referral-wallet-review" */).then(c => wrapFunctional(c.default || c))
export const SecurityConfirmForm = () => import('../../components/Security/ConfirmForm/ConfirmForm.vue' /* webpackChunkName: "components/security-confirm-form" */).then(c => wrapFunctional(c.default || c))
export const SecurityPasswordForm = () => import('../../components/Security/Password/PasswordForm.vue' /* webpackChunkName: "components/security-password-form" */).then(c => wrapFunctional(c.default || c))
export const SettingsInformationForm = () => import('../../components/Settings/Information/InformationForm.vue' /* webpackChunkName: "components/settings-information-form" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfileForm = () => import('../../components/Settings/Profile/ProfileForm.vue' /* webpackChunkName: "components/settings-profile-form" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfileImage = () => import('../../components/Settings/Profile/ProfileImage.vue' /* webpackChunkName: "components/settings-profile-image" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfileInformation = () => import('../../components/Settings/Profile/ProfileInformation.vue' /* webpackChunkName: "components/settings-profile-information" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfileNotification = () => import('../../components/Settings/Profile/ProfileNotification.vue' /* webpackChunkName: "components/settings-profile-notification" */).then(c => wrapFunctional(c.default || c))
export const SettingsWalletHeader = () => import('../../components/Settings/Wallet/WalletHeader.vue' /* webpackChunkName: "components/settings-wallet-header" */).then(c => wrapFunctional(c.default || c))
export const SettingsWalletList = () => import('../../components/Settings/Wallet/WalletList.vue' /* webpackChunkName: "components/settings-wallet-list" */).then(c => wrapFunctional(c.default || c))
export const SettingsWalletView = () => import('../../components/Settings/Wallet/WalletView.vue' /* webpackChunkName: "components/settings-wallet-view" */).then(c => wrapFunctional(c.default || c))
export const SystemAppLanguages = () => import('../../components/System/AppLanguages/AppLanguages.vue' /* webpackChunkName: "components/system-app-languages" */).then(c => wrapFunctional(c.default || c))
export const SystemGraphicsLineChart = () => import('../../components/System/Graphics/LineChart.vue' /* webpackChunkName: "components/system-graphics-line-chart" */).then(c => wrapFunctional(c.default || c))
export const SystemHeaderTheHeader = () => import('../../components/System/Header/TheHeader.vue' /* webpackChunkName: "components/system-header-the-header" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTheModals = () => import('../../components/System/Modals/TheModals.vue' /* webpackChunkName: "components/system-modals-the-modals" */).then(c => wrapFunctional(c.default || c))
export const SystemNavigationTheNavigation = () => import('../../components/System/Navigation/TheNavigation.vue' /* webpackChunkName: "components/system-navigation-the-navigation" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsTheNotifications = () => import('../../components/System/Notifications/TheNotifications.vue' /* webpackChunkName: "components/system-notifications-the-notifications" */).then(c => wrapFunctional(c.default || c))
export const SystemTransactionsTheTransactions = () => import('../../components/System/Transactions/TheTransactions.vue' /* webpackChunkName: "components/system-transactions-the-transactions" */).then(c => wrapFunctional(c.default || c))
export const SystemTransactionsHeader = () => import('../../components/System/Transactions/TransactionsHeader.vue' /* webpackChunkName: "components/system-transactions-header" */).then(c => wrapFunctional(c.default || c))
export const UIDropdownVDropdown = () => import('../../components/UI/Dropdown/VDropdown.vue' /* webpackChunkName: "components/u-i-dropdown-v-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UIDropdownVDropdownItem = () => import('../../components/UI/Dropdown/VDropdownItem.vue' /* webpackChunkName: "components/u-i-dropdown-v-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const UISwitchersVPointSwitcher = () => import('../../components/UI/Switchers/VPointSwitcher.vue' /* webpackChunkName: "components/u-i-switchers-v-point-switcher" */).then(c => wrapFunctional(c.default || c))
export const UISwitchersVTextSwitcher = () => import('../../components/UI/Switchers/VTextSwitcher.vue' /* webpackChunkName: "components/u-i-switchers-v-text-switcher" */).then(c => wrapFunctional(c.default || c))
export const UIUploaderVCamUploader = () => import('../../components/UI/Uploader/VCamUploader.vue' /* webpackChunkName: "components/u-i-uploader-v-cam-uploader" */).then(c => wrapFunctional(c.default || c))
export const UIUploaderVUploader = () => import('../../components/UI/Uploader/VUploader.vue' /* webpackChunkName: "components/u-i-uploader-v-uploader" */).then(c => wrapFunctional(c.default || c))
export const WalletAbout = () => import('../../components/Wallet/About/WalletAbout.vue' /* webpackChunkName: "components/wallet-about" */).then(c => wrapFunctional(c.default || c))
export const ServicesEarnColumn = () => import('../../components/Services/Earn/Column/EarnColumn.vue' /* webpackChunkName: "components/services-earn-column" */).then(c => wrapFunctional(c.default || c))
export const ServicesEarnContainer = () => import('../../components/Services/Earn/Container/EarnContainer.vue' /* webpackChunkName: "components/services-earn-container" */).then(c => wrapFunctional(c.default || c))
export const ServicesEarnGraphics = () => import('../../components/Services/Earn/Graphics/EarnGraphics.vue' /* webpackChunkName: "components/services-earn-graphics" */).then(c => wrapFunctional(c.default || c))
export const ServicesEarnGraphicsChart = () => import('../../components/Services/Earn/Graphics/EarnGraphicsChart.vue' /* webpackChunkName: "components/services-earn-graphics-chart" */).then(c => wrapFunctional(c.default || c))
export const ServicesEarnInnerServiceControl = () => import('../../components/Services/Earn/Inner/ServiceControl.vue' /* webpackChunkName: "components/services-earn-inner-service-control" */).then(c => wrapFunctional(c.default || c))
export const ServicesEarnInnerServiceStatistics = () => import('../../components/Services/Earn/Inner/ServiceStatistics.vue' /* webpackChunkName: "components/services-earn-inner-service-statistics" */).then(c => wrapFunctional(c.default || c))
export const SettingsProfileCardNotificationCard = () => import('../../components/Settings/Profile/Card/NotificationCard.vue' /* webpackChunkName: "components/settings-profile-card-notification-card" */).then(c => wrapFunctional(c.default || c))
export const SystemAppLoadingCircleLoad = () => import('../../components/System/AppLoading/Circle/CircleLoad.vue' /* webpackChunkName: "components/system-app-loading-circle-load" */).then(c => wrapFunctional(c.default || c))
export const SystemAppLoadingDotsLoader = () => import('../../components/System/AppLoading/Dots/Loader.vue' /* webpackChunkName: "components/system-app-loading-dots-loader" */).then(c => wrapFunctional(c.default || c))
export const SystemCardsStatisticCard = () => import('../../components/System/Cards/Statistic/StatisticCard.vue' /* webpackChunkName: "components/system-cards-statistic-card" */).then(c => wrapFunctional(c.default || c))
export const SystemCardsStrategyCard = () => import('../../components/System/Cards/Strategy/StrategyCard.vue' /* webpackChunkName: "components/system-cards-strategy-card" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorEmbed = () => import('../../components/System/Editor/EditorViews/EditorEmbed.vue' /* webpackChunkName: "components/system-editor-embed" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorHeader = () => import('../../components/System/Editor/EditorViews/EditorHeader.vue' /* webpackChunkName: "components/system-editor-header" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorImage = () => import('../../components/System/Editor/EditorViews/EditorImage.vue' /* webpackChunkName: "components/system-editor-image" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorIndex = () => import('../../components/System/Editor/EditorViews/EditorIndex.vue' /* webpackChunkName: "components/system-editor-index" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorInfo = () => import('../../components/System/Editor/EditorViews/EditorInfo.vue' /* webpackChunkName: "components/system-editor-info" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorOrderedList = () => import('../../components/System/Editor/EditorViews/EditorOrderedList.vue' /* webpackChunkName: "components/system-editor-ordered-list" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorParagraph = () => import('../../components/System/Editor/EditorViews/EditorParagraph.vue' /* webpackChunkName: "components/system-editor-paragraph" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorQuote = () => import('../../components/System/Editor/EditorViews/EditorQuote.vue' /* webpackChunkName: "components/system-editor-quote" */).then(c => wrapFunctional(c.default || c))
export const SystemEditorUnorderedList = () => import('../../components/System/Editor/EditorViews/EditorUnorderedList.vue' /* webpackChunkName: "components/system-editor-unordered-list" */).then(c => wrapFunctional(c.default || c))
export const SystemHeaderBurgerTheBurger = () => import('../../components/System/Header/Burger/TheBurger.vue' /* webpackChunkName: "components/system-header-burger-the-burger" */).then(c => wrapFunctional(c.default || c))
export const SystemHeaderLogo = () => import('../../components/System/Header/Logo/HeaderLogo.vue' /* webpackChunkName: "components/system-header-logo" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsCropModal = () => import('../../components/System/Modals/CropModal/CropModal.vue' /* webpackChunkName: "components/system-modals-crop-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsReferralLinkmodalCreateReferralLinkModal = () => import('../../components/System/Modals/ReferralLinkmodal/CreateReferralLinkModal.vue' /* webpackChunkName: "components/system-modals-referral-linkmodal-create-referral-link-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsSettingsWalletModal = () => import('../../components/System/Modals/SettingsWalletModal/SettingsWalletModal.vue' /* webpackChunkName: "components/system-modals-settings-wallet-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationControl = () => import('../../components/System/Modals/TwoStepVerification/TwoStepVerificationControl.vue' /* webpackChunkName: "components/system-modals-two-step-verification-control" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationModal = () => import('../../components/System/Modals/TwoStepVerification/TwoStepVerificationModal.vue' /* webpackChunkName: "components/system-modals-two-step-verification-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationProgress = () => import('../../components/System/Modals/TwoStepVerification/TwoStepVerificationProgress.vue' /* webpackChunkName: "components/system-modals-two-step-verification-progress" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalCameraComponent = () => import('../../components/System/Modals/VerificationModal/CameraComponent.vue' /* webpackChunkName: "components/system-modals-verification-modal-camera-component" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationAttachmentColumn = () => import('../../components/System/Modals/VerificationModal/VerificationAttachmentColumn.vue' /* webpackChunkName: "components/system-modals-verification-attachment-column" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationControl = () => import('../../components/System/Modals/VerificationModal/VerificationControl.vue' /* webpackChunkName: "components/system-modals-verification-control" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModal = () => import('../../components/System/Modals/VerificationModal/VerificationModal.vue' /* webpackChunkName: "components/system-modals-verification-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalTop = () => import('../../components/System/Modals/VerificationModal/VerificationModalTop.vue' /* webpackChunkName: "components/system-modals-verification-modal-top" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationProgress = () => import('../../components/System/Modals/VerificationModal/VerificationProgress.vue' /* webpackChunkName: "components/system-modals-verification-progress" */).then(c => wrapFunctional(c.default || c))
export const SystemNavigationLaptop = () => import('../../components/System/Navigation/Laptop/NavigationLaptop.vue' /* webpackChunkName: "components/system-navigation-laptop" */).then(c => wrapFunctional(c.default || c))
export const SystemNavigationMobile = () => import('../../components/System/Navigation/Mobile/NavigationMobile.vue' /* webpackChunkName: "components/system-navigation-mobile" */).then(c => wrapFunctional(c.default || c))
export const SystemNavigationNavDropdown = () => import('../../components/System/Navigation/NavDropdown/NavDropdown.vue' /* webpackChunkName: "components/system-navigation-nav-dropdown" */).then(c => wrapFunctional(c.default || c))
export const SystemNavigationRow = () => import('../../components/System/Navigation/NavigationRow/NavigationRow.vue' /* webpackChunkName: "components/system-navigation-row" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsErrorNotificationError = () => import('../../components/System/Notifications/Error/NotificationError.vue' /* webpackChunkName: "components/system-notifications-error-notification-error" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsErrorNotificationErrorBody = () => import('../../components/System/Notifications/Error/NotificationErrorBody.vue' /* webpackChunkName: "components/system-notifications-error-notification-error-body" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsErrorNotificationErrorTop = () => import('../../components/System/Notifications/Error/NotificationErrorTop.vue' /* webpackChunkName: "components/system-notifications-error-notification-error-top" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsGeneralNotification = () => import('../../components/System/Notifications/GeneralNotification/GeneralNotification.vue' /* webpackChunkName: "components/system-notifications-general-notification" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsRejectNotificationReject = () => import('../../components/System/Notifications/Reject/NotificationReject.vue' /* webpackChunkName: "components/system-notifications-reject-notification-reject" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsSuccessNotificationSuccess = () => import('../../components/System/Notifications/Success/NotificationSuccess.vue' /* webpackChunkName: "components/system-notifications-success-notification-success" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsSuccessNotificationSuccessBody = () => import('../../components/System/Notifications/Success/NotificationSuccessBody.vue' /* webpackChunkName: "components/system-notifications-success-notification-success-body" */).then(c => wrapFunctional(c.default || c))
export const SystemNotificationsSuccessNotificationSuccessTop = () => import('../../components/System/Notifications/Success/NotificationSuccessTop.vue' /* webpackChunkName: "components/system-notifications-success-notification-success-top" */).then(c => wrapFunctional(c.default || c))
export const SystemTransactionsRow = () => import('../../components/System/Transactions/Row/TransactionsRow.vue' /* webpackChunkName: "components/system-transactions-row" */).then(c => wrapFunctional(c.default || c))
export const UIUploaderImageUploaderVCameraUploader = () => import('../../components/UI/Uploader/ImageUploader/VCameraUploader.vue' /* webpackChunkName: "components/u-i-uploader-image-uploader-v-camera-uploader" */).then(c => wrapFunctional(c.default || c))
export const UIUploaderImageUploaderVImageUploader = () => import('../../components/UI/Uploader/ImageUploader/VImageUploader.vue' /* webpackChunkName: "components/u-i-uploader-image-uploader-v-image-uploader" */).then(c => wrapFunctional(c.default || c))
export const SystemHeaderControlAccount = () => import('../../components/System/Header/Control/Account/Account.vue' /* webpackChunkName: "components/system-header-control-account" */).then(c => wrapFunctional(c.default || c))
export const SystemHeaderControlBalance = () => import('../../components/System/Header/Control/Balance/Balance.vue' /* webpackChunkName: "components/system-header-control-balance" */).then(c => wrapFunctional(c.default || c))
export const SystemHeaderControlNotifications = () => import('../../components/System/Header/Control/Notifications/Notifications.vue' /* webpackChunkName: "components/system-header-control-notifications" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsStrategyDepositModal = () => import('../../components/System/Modals/Strategy/DepositModal/StrategyDepositModal.vue' /* webpackChunkName: "components/system-modals-strategy-deposit-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsStrategyWithdrawModal = () => import('../../components/System/Modals/Strategy/WithdrawModal/StrategyWithdrawModal.vue' /* webpackChunkName: "components/system-modals-strategy-withdraw-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationFinalStep = () => import('../../components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationFinalStep.vue' /* webpackChunkName: "components/system-modals-two-step-verification-final-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationFirstStep = () => import('../../components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationFirstStep.vue' /* webpackChunkName: "components/system-modals-two-step-verification-first-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationForthStep = () => import('../../components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationForthStep.vue' /* webpackChunkName: "components/system-modals-two-step-verification-forth-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationSecondStep = () => import('../../components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationSecondStep.vue' /* webpackChunkName: "components/system-modals-two-step-verification-second-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsTwoStepVerificationThirdStep = () => import('../../components/System/Modals/TwoStepVerification/Steps/TwoStepVerificationThirdStep.vue' /* webpackChunkName: "components/system-modals-two-step-verification-third-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalVerificationStepsFinalStep = () => import('../../components/System/Modals/VerificationModal/VerificationSteps/FinalStep.vue' /* webpackChunkName: "components/system-modals-verification-modal-verification-steps-final-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalVerificationStepsForthStep = () => import('../../components/System/Modals/VerificationModal/VerificationSteps/ForthStep.vue' /* webpackChunkName: "components/system-modals-verification-modal-verification-steps-forth-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalVerificationStepsSecondStep = () => import('../../components/System/Modals/VerificationModal/VerificationSteps/SecondStep.vue' /* webpackChunkName: "components/system-modals-verification-modal-verification-steps-second-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalVerificationStepsStartStep = () => import('../../components/System/Modals/VerificationModal/VerificationSteps/StartStep.vue' /* webpackChunkName: "components/system-modals-verification-modal-verification-steps-start-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsVerificationModalVerificationStepsThirdStep = () => import('../../components/System/Modals/VerificationModal/VerificationSteps/ThirdStep.vue' /* webpackChunkName: "components/system-modals-verification-modal-verification-steps-third-step" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsWalletDepositModal = () => import('../../components/System/Modals/Wallet/DepositModal/WalletDepositModal.vue' /* webpackChunkName: "components/system-modals-wallet-deposit-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsWalletDepositModalForm = () => import('../../components/System/Modals/Wallet/DepositModal/WalletDepositModalForm.vue' /* webpackChunkName: "components/system-modals-wallet-deposit-modal-form" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsWalletDepositModalTop = () => import('../../components/System/Modals/Wallet/DepositModal/WalletDepositModalTop.vue' /* webpackChunkName: "components/system-modals-wallet-deposit-modal-top" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsWalletWithdrawModal = () => import('../../components/System/Modals/Wallet/WithdrawModal/WalletWithdrawModal.vue' /* webpackChunkName: "components/system-modals-wallet-withdraw-modal" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsWalletWithdrawModalForm = () => import('../../components/System/Modals/Wallet/WithdrawModal/WalletWithdrawModalForm.vue' /* webpackChunkName: "components/system-modals-wallet-withdraw-modal-form" */).then(c => wrapFunctional(c.default || c))
export const SystemModalsWalletWithdrawModalTop = () => import('../../components/System/Modals/Wallet/WithdrawModal/WalletWithdrawModalTop.vue' /* webpackChunkName: "components/system-modals-wallet-withdraw-modal-top" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
